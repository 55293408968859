import {helpers} from 'vuelidate/lib/validators'
// import vimeoRegex from 'vimeo-regex';

export const validationMap = {
    required: () => 'Обязательное поле',
    email: () => 'Некорректная почта',
    sameAs: () => 'Пароль не совпадает',
    phoneRu: () => `Некорректный номер`,
    minLength: (validator) => `Минимальная длина от ${validator.$params.minLength.min} символов`,
    maxLength: (validator) => `Максимальная длина ${validator.$params.maxLength.max} символов`,
    unique: (validator) => validator.$params.unique.messageName,
    url: () => `Ссылка некорректна`,
}

export function youtubeLink(value) {
    return /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/.test(value)
}
export function vimeoLink(value) {
    if( value instanceof File || !value)
        return false
    return /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/|\/videos\/|video\/|)(\d+)(?:|\/\?)/.test(value)

  //  return /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/.test(value)
}


export const unique = (field, searchBy, messageName) =>
    helpers.withParams(
        {type: 'unique', messageName},
        (value, vm) => {
            return !vm[field].find(vmField => vmField[searchBy] === value)
        }
    )


export function validate(validator) {
    validator.$touch()
    return !validator.$error
}
